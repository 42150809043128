import React, { Component } from 'react'
import { connect } from 'react-redux'
import { tryGetVenueProfile, tryPostVenueProfile, updateField } from '../actions/services'
import * as styles from '../assets/styles'
import { VenueInformationForm, SocialMediaForm, MarketingInformationForm } from '../components/profile'

class App extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    const {
      venueUrlKey,
      venueSettings: { product_provision_package: productProvisionPackage },
    } = window.globalInit

    if (['MARKETING_ALPHA', 'MARKETING_BETA'].includes(productProvisionPackage)) {
      window.location.pathname = `manager2/${venueUrlKey}/marketing/venueprofile`
    } else {
      this.props.actions.tryGetVenueProfile(this.props.venueId)
    }
  }

  render() {
    return (
      <div>
        <div id="content-header">
          <h2>Venue Profile</h2>
        </div>
        <div style={styles.Page}>
          <VenueInformationForm venueProfile={this.props.venueProfile} appState={this.props.appState} actions={this.props.actions} />
          <SocialMediaForm venueProfile={this.props.venueProfile} appState={this.props.appState} actions={this.props.actions} />
          <MarketingInformationForm
            MEDIA_URL={this.props.MEDIA_URL}
            venueProfile={this.props.venueProfile}
            appState={this.props.appState}
            actions={this.props.actions}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  venueProfile: state.venueProfile,
  appState: state.appState,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    updateField: (fieldName, val, error) => {
      dispatch(updateField(fieldName, val, error))
    },
    tryGetVenueProfile: venueId => {
      dispatch(tryGetVenueProfile(venueId))
    },
    tryPostVenueProfile: venueProfile => {
      dispatch(tryPostVenueProfile(venueProfile))
    },
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
